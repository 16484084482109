@import "./style/font.scss";
@import "react-toastify/dist/ReactToastify.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-variable: "Satoshi-Variable";
  --font-variableitalic: "Satoshi-VariableItalic";
  --font-light: "Satoshi-Light";
  --font-lightItalic: "Satoshi-LightItalic";
  --font-regular: "Satoshi-Regular";
  --font-medium: "Satoshi-Medium";
  --color-gardient: linear-gradient(
    305.92deg,
    #07efeb 13.43%,
    #2ab7f1 35.23%,
    #4a83f7 66.76%,
    #7738ff 91.41%
  );
  // --color-bg-main: #12151b;
  --color-text: #fff;
  --color-text-cancel: #07efeb;
  --color-text-blue: #4a83f7;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
}

body {
  font-family: var(--font-medium);
  font-style: normal;
  // color: var(--color-text);
  background-color: var(--theme-primary);
}

.custom-inp {
  background: var(--color-gardient);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 1px;
}

.custom-btn {
  background: var(--color-gardient);
  color: #fff;
}
.custom-btn-cancel {
  background: var(--color-gardient);
  z-index: 0;
  border-radius: 32px;
  color: var(--color-text-cancel);
  padding: 1px;
}

.custom-selected {
  background: var(--color-gardient);
  width: 70px;
  padding: 1px;
  border-radius: 4px;
}

.dropdown-animation {
  animation: animationDropdown linear 0.1s;
  transform-origin: top right;
  transform: scale(1);
}

@keyframes animationDropdown {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.dropdown-hide {
  animation: dropdownHide ease-in-out 0.1s;
  transform-origin: top right;
  transform: scale(0);
}

@keyframes dropdownHide {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.span-gardient {
  background: linear-gradient(
    305.92deg,
    #07efeb 13.43%,
    #2ab7f1 35.23%,
    #4a83f7 66.76%,
    #7738ff 91.41%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-cancel {
  color: var(--color-text-cancel);
}

.blue-text {
  color: var(--color-text-blue);
}

.Toastify__toast-body {
  font-size: 14px;
}

.text-ellipse {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description-ellipse {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gradient-text-color {
  background: linear-gradient(
    305.92deg,
    #07efeb 13.43%,
    #2ab7f1 35.23%,
    #4a83f7 66.76%,
    #7738ff 91.41%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.button-category-focus {
  background-image: linear-gradient(
    to left top,
    rgba(7, 239, 235, 1),
    rgba(42, 183, 241, 1),
    rgba(74, 131, 247, 1),
    rgba(119, 56, 255, 1)
  );
}

.line-height {
  background-image: linear-gradient(
    to right,
    rgba(94, 98, 114, 0),
    rgba(94, 98, 114, 1),
    rgba(94, 98, 114, 1),
    rgba(94, 98, 114, 0)
  );
  width: 24px;
  height: 1px;
  position: absolute;
  transform: rotate(90deg);
}

.skeleton {
   .dark {
      .ant-skeleton-avatar
        {background: rgba(0,0,0,1);}
      .ant-skeleton-input{
        background: rgba(0,0,0,1);
      }
  }
    .light{
      .ant-skeleton-avatar{
        background: rgba(0,0,0,0.1)
      }
      .ant-skeleton-input{
        background: rgba(0,0,0,0.1)
      }
    }
}
